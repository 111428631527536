import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import CardMedia from '@material-ui/core/CardMedia';

const styles = theme => ({ 
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'space-around',
        overflow: 'hidden',
        position: 'absolute',
        top: 16
    },
    wrapper: {
        position: 'relative',
    },
    duwunLogo: {
        cursor: 'pointer', 
        width: 81,
        height: 20,
        backgroundSize: 81, 
        position: 'absolute',
        top: 10,
        left: 16
    },
    starLogo: {
        cursor: 'pointer', 
        width: 26,
        height: 26,
        backgroundSize: 26, 
        position: 'absolute',
        top: 10,
        right: 16
    }
})
 function VideoOverlaylogo(props) {
     const { classes } = props;
    return (
        <div >
            {/* <div className={classes.wrapper}> */}
                <CardMedia
                className={classes.duwunLogo}
                image="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/DuwunLogoOrange.svg"
                />
                <CardMedia
                   className={classes.starLogo}
                    image="https://duwun-data.sgp1.cdn.digitaloceanspaces.com/static/image/starOrange.svg"
                />
            </div>
        
        // </div>
    )
}
export default withStyles(styles)(VideoOverlaylogo);
