import React, { Component } from "react";
import { withStyles } from '@material-ui/core/styles';
import Typography from '@material-ui/core/Typography';
import { Link } from "routes";

const styles = theme => ({ 
    featureSponsorContainer: {
        position: 'absolute',
        display: 'flex',
      },
    // featureSponsorButton: { // OLD DESIGN
    //     color: '#fff',
    //     border: '1px solid rgba(225,225,225, 0.5)', 
    //     background: 'rgba(0, 0, 0,0.6)',
    //     textTransform: 'capitalize', 
    //     padding: '4px 10px',
    //     borderRadius: 3,
    //     marginRight: 10
    // },
    featureSponsorButton: {
        color: theme.palette.whiteColor.contrastText,
        background: '#000',
        fontWeight: 'bold',
        fontSize: 18,
        lineHeight: '23px',
        textAlign: 'center',
        width: 93,
        height: 25,
        letterSpacing: '0.5px',
        padding: '0px 4px 7px 5px'
    }
     
})
export function SponsoredBadge(props) {
    const { sponsored } = props;
    return(
        sponsored ? 
            <Typography style={{ 
                    fontSize: 9,
                    fontWeight: 'bold',
                    padding: '3px 4px 4px',
                    margin: '0px 10px 4px 0px',
                    background: '#F9461E',
                    color: '#fff',
                    display: 'inline',
            }} >
                Sponsored
            </Typography>
        : null
    )
}
class SponsorFeatureBadge extends Component {
  render() {
    const { classes, featured, sponsored, url, positionStyle } = this.props;
    return (
        <span className={classes.featureSponsorContainer} style={positionStyle} >
            { featured ?  
                // <Link route={`${url}`}>
                //     <a className="anchor-link">
                    <Typography className={classes.featureSponsorButton} >
                        Featured
                    </Typography>
                //     </a>
                // </Link>
                : null
            }
                    
            { sponsored ? 
                <Typography className={classes.featureSponsorButton} >
                    Sponsored
                </Typography>
                : null
            } 
        </span>
    );
  }
}

export default withStyles(styles)(SponsorFeatureBadge);
