// thumb for poster on player of video detail page(desktop)
// thumb_mobile for poster on player of video detail page(mobile)

// next_video_teaser for next up section of video detail page(desktop)
// mobile_next_video_teaser for next up section of video detail page(mobile)

// video_section_teaser for card of video page(desktop)
// mobile_video_section_teaser for card of video page(mobile)



import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';
import { Link } from "routes";
import {getDesktopNewsItemVideoThumbnail, extractYoutubeVideoID} from '/utils/common'
import Fab from '@material-ui/core/Fab';
import CardMedia from '@material-ui/core/CardMedia';
import Card from '@material-ui/core/Card';
import PlayIconButton from '/components/playIconButton';
import VideoOverlaylogo from '/components/videoOverlaylogo';
import VideoDuration from './VideoDuration';
import Lazyload from 'react-lazyload';
import PlaylistPosterOverlay from './PlaylistPosterOverlay';

const styles = theme => ({
  root: {
    flexGrow: 1,
    position: 'relative'
  },
  card: {
    position: 'relative',
    borderRadius: 0
  },
  // for Ko Min Min's playicon design
  // playBtn: {
  //   background: 'rgba(255, 255, 255, 0.4)', 
  //   position: 'absolute', 
  //   top: '40%',
  //   width: 35,
  //   height: 35,
  //   '&::before': {
  //       content: "''",
  //       position: 'absolute',
  //       width: 43,
  //       height: 43,
  //       borderRadius: '50%',
  //       border: '1px solid #fff'
  //   }
  // }
  playBtn: {
    background: 'rgba(0, 0, 0, 0.4)', 
    position: 'absolute', 
    width: 35,
    height: 35,
    border: '1px solid #fff',
    verticalAlign: 'middle',
    position: 'absolute',
    top: 0,
    bottom: 0,
    margin: 'auto',

  }
});
function Poster(props){
  const { item, classes, videoImgHeight, videoPoster, bigBtn, routeName, playlistId, isUpnextItems } = props;
  const tvTypeIndex = item.categories && item.categories.findIndex((category) => category.type === 'TV')
  const brandRouteName = tvTypeIndex >= 0 ? 'brandTvDetail' : 'brandVideoDetail';
  return(
    <div className={classes.root}>
    <Card className={classes.card}>
      {!(item.playlist_id && !item.videos) ?
      <Link route={item.sponsor ? brandRouteName : routeName} params={item.sponsor? { brand: item.sponsor.slug , id: item.slug } : !item.playlist_id ? playlistId ? { id: item.slug, list: playlistId } : { id: item.slug } : { id: item.videos.slug, list: item.playlist_id }}>
        <a className="anchor-link">
          <CardMedia
            style={{ cursor: 'pointer',  paddingTop: '56.25%', height: videoImgHeight }}
            image={videoPoster ? videoPoster : 'https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/image/default-video-1000x500.png'}
          />
          {!isUpnextItems ? !item.is_sponsor && <VideoOverlaylogo /> : null}
          {item.playlist_id ? <p className='playlistOverlay'> <Icon style={{paddingTop: 5}}>play_arrow</Icon>Play All</p> : null}
          {
            !item.playlist_id ? 
            <React.Fragment>
              <PlayIconButton bigBtn={bigBtn} />
              <VideoDuration vDuration={item.duration} />
            </React.Fragment>
            :
            <PlaylistPosterOverlay videoCount={item.video_count} />
          }

        </a>
      </Link>
      : 
      <div style={{position: 'relative'}}>
        <div style={{ position: 'absolute', top: 0, left: 0, right: 0, bottom: 0 }}>No video in this playlist.</div>
        <CardMedia
          style={{ cursor: 'pointer',  paddingTop: '56.25%', height: videoImgHeight }}
          image={'https://duwun-data.sgp1.cdn.digitaloceanspaces.com/react-assets/image/default-video-1000x500.png'}
        />
      </div>
      }
    </Card>
  </div>
  )
}
function VideoCardPoster(props) {
  const { item, classes, videoImgHeight, videoPoster, bigBtn, routeName, playlistId, lazyload, isUpnextItems } = props;
  let isLazyload = lazyload ? lazyload : null;
  return (
    isLazyload ? 
    <Lazyload height={100}>
      <Poster item={item} isUpnextItems={isUpnextItems} classes={classes} videoImgHeight={videoImgHeight} videoPoster={videoPoster} bigBtn={bigBtn} routeName={routeName} playlistId={playlistId} />
    </Lazyload>
    :
      <Poster item={item} isUpnextItems={isUpnextItems} classes={classes} videoImgHeight={videoImgHeight} videoPoster={videoPoster} bigBtn={bigBtn} routeName={routeName} playlistId={playlistId} />
      
    
  );
}


VideoCardPoster.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoCardPoster);
