import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TimeAgo from "react-timeago";
import { Link } from "routes";
import fontUniOrConvertZawgyi from '/utils/fontUniOrConvertZawgyi';
import ViewCount from "/components/viewCount";
import BrandTeaserLogo from '/components/brandTeaserLogo';
import { SponsoredBadge } from '/components/sponsorFeatureBadge';
import styled from 'styled-components';
import AuthorAndTime from '/components/authorAndTime';

const styles = theme => ({
  root: {
    flexGrow: 1,
    padding: '0px 0px  14px',
  },
  time: {
    cursor: 'pointer',
    fontSize: 12,
    display: 'inline',
  },
  cursor: {
    cursor: 'pointer',
  },
  title: {
    lineHeight: '24px',
    fontWeight: 'normal',
    overflowWrap: 'break-word',
    fontWeight: 'bold'
  },
 
  dot: {
    margin: '0px 7px',
    fontWeight: 'bold',
    opacity: 0.5
  },
 
});

class VideoCardParts extends React.Component {
  render(){
  const { item, classes, customProps, routeName, color, playlistId, isDesktopPlaylist, viewCountStyle, isVideoDetail, typoComponent } = this.props;
  const views = item.ga != null ? item.ga['ga:pageviews'] : null
  const viewCount = viewCountStyle || null;
  const searchColor = this.props.color ? color : '#fff';
  const isDesktopPlaylistProps = isDesktopPlaylist || null;
  const title = item.title ? item.title : null;
  const titleLength = title != null ? title.length : null;
  const sliceTitle = title != null  ? title.substr(0, 70) : null;
  const videoTitle = titleLength > 70 && isDesktopPlaylist ? sliceTitle.concat('...') : title
  const tvTypeIndex = item.categories && item.categories.findIndex((category) => category.type === 'TV')
  const brandRouteName = tvTypeIndex >= 0 ? 'brandTvDetail' : 'brandVideoDetail';
  const cateRouteName = tvTypeIndex >= 0 ? 'categoryTv' : 'categoryVideos';

  const VideoCategoryTitleStyle = styled.span`
  position: relative;
  &::before {
    content: "";
    background: #F9461E;
    height: 9px;
    position: absolute;
    width: 3px;
    top: 3px;
  },
`;
  return (
    <div>
      <Grid
        container
        direction="row"
        justify="space-between"
        alignItems="flex-start"
      >
        {!isVideoDetail &&
        <div style={{ display: 'flex' }}>
          {
            item.categories && item.categories.length > 0 && item.categories.map((categoryItem, index) => {
              const isCategory = (categoryItem.type === 'VIDEO' || categoryItem.type === 'TV') && categoryItem.sub_categories;
              return(
                <React.Fragment key={index}>
                  {
                    index < 1 && 
                      <Link 
                        route={ // check category and subcategory of video
                          isCategory ? 
                          categoryItem.type === 'VIDEO' ? 'videos' : 'tv' // check Video and TV section
                          : cateRouteName }   
                        params={isCategory ? '' : { id: categoryItem.slug }}
                       >
                        <a className="anchor-link">
                          <VideoCategoryTitleStyle />
                            <Typography
                              gutterBottom
                              style={{
                                color: '#F9461E',
                                textTransform: 'capitalize',
                                cursor: 'pointer',
                                paddingLeft: 8,
                                fontSize: 11,
                                fontWeight: 'bold'
                              }}
                            >
                            <span className="sub_categories">
                                {categoryItem.name}
                            </span>
                            </Typography>
                        </a>
                    </Link>
                  }
                </React.Fragment>
              )
            })
          }
          { item.categories && item.categories.length > 1 && !item.playlist_id && 
          <Link route={item.sponsor ? brandRouteName : routeName} params={item.sponsor ? { brand: item.sponsor.slug , id: item.slug } : !item.playlist_id ? playlistId ? { id: item.slug, list: playlistId } : { id: item.slug } : { id: item.videos.slug, list: item.playlist_id }}>
            <a className="anchor-link" style={{ marginTop: '-5px' }}>
              <span style={{ paddingLeft: 10, color: '#F9461E' }}>...</span> 
            </a>
          </Link>
          }
        </div>
        }
        {
          item.sponsor ? !isVideoDetail && <BrandTeaserLogo brandTeaserLogo={item.sponsor.logo} brandSlug={item.sponsor.slug} /> : null
        }
      </Grid>
  
      {routeName ?
       !(item.playlist_id && !item.videos) ?
       <Link route={item.sponsor ? brandRouteName : routeName} params={item.sponsor ? { brand: item.sponsor.slug , id: item.slug } : !item.playlist_id ? playlistId ? { id: item.slug, list: playlistId } : { id: item.slug } : { id: item.videos.slug, list: item.playlist_id }}>
       <a className="anchor-link">
            <Typography component={ typoComponent ? typoComponent: "div" } gutterBottom={!isDesktopPlaylistProps} className={`${classes.title} ${classes.cursor}`} style={{color: searchColor, paddingRight: isDesktopPlaylistProps ? 10 : ''}} {...customProps}>
            <SponsoredBadge sponsored={item.is_sponsor} />
            {fontUniOrConvertZawgyi(videoTitle)}
            </Typography>
          </a>
        </Link>
        :
        <Typography component={ typoComponent ? typoComponent: "div" } gutterBottom={!isDesktopPlaylistProps} className={`${classes.title} ${classes.cursor}`} style={{color: searchColor, paddingRight: isDesktopPlaylistProps ? 10 : ''}} {...customProps}>
          <SponsoredBadge sponsored={item.is_sponsor} /> 
          {fontUniOrConvertZawgyi(videoTitle)}
        </Typography>
        :
        <Typography component={ typoComponent ? typoComponent: "div" } className={classes.title} style={{color: searchColor}} {...customProps}>
           <SponsoredBadge sponsored={item.is_sponsor} />
           {fontUniOrConvertZawgyi(videoTitle)}
        </Typography>         
      }
      {
        // !item.playlist_id ?
        <AuthorAndTime views={views} item={item} viewCount={viewCount} linkColor={searchColor} customStyle={{color: searchColor, opacity: 0.5}} />

        // <Typography variant="caption" style={{color: searchColor}}>
        //   {item.author && 
        //     <>
        //     <Link route="authorProfile" params={{ id: item.author.email ? item.author.email : 'nothing' }}>
        //         <a className="anchor-link" style={{color: searchColor, opacity: 0.5}}>
        //         {item.author.name}
        //         </a>
        //     </Link>
        //     <span className={classes.dot} >•</span>
        //     </>
        //   }
        //   <TimeAgo className={classes.date} style={{opacity: 0.5}} date={item.published_date ? item.published_date.date: null} />
        //   {!item.playlist_id && views ? 
        //     <span style={{ float: 'right', opacity: 0.5 }} {...viewCount}>
        //       <ViewCount viewCount={views} />
        //     </span>
        //   : null
        //   }

        // </Typography>
        // : null
      }
    
    </div>
  );
}
}

VideoCardParts.propTypes = {
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(VideoCardParts);
