import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Icon from '@material-ui/core/Icon';

const styles = theme => ({
    playlistStyleWrapper: {
        position: 'absolute',
        top: 0,
        background: 'rgba(0, 0, 0, 0.8)',
        right: 0,
        width: '50%',
        height: '100%'
    },
    videoCount: {
        marginTop: '32%',
        textAlign: 'center',
        color: '#fff',
        fontSize: 20
    
    },
    playlistIcon: {
        color: '#fff',
        fontSize: 25,
        marginLeft: '42%'

    }


})
class PlaylistPosterOverlay extends React.Component {
    render(){
    const { videoCount, classes } = this.props;
    return (
        <div className={classes.playlistStyleWrapper}>
              <div className={classes.videoCount}>{videoCount}</div>
              <Icon className={classes.playlistIcon}>playlist_play</Icon>
              </div>
  );
}
}


export default withStyles(styles)(PlaylistPosterOverlay);
